body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.apprenticeship-btn, .apprenticeship-card{
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 1rem 1rem 0 0;
}
.apprenticeship-btn-open{
    border-radius: 1rem 1rem 0 0;
}

.apprenticeship-btn-close{
    border-radius: 1rem;
}

.apprenticeship-card{
    border-radius:0 0 1rem 1rem;
    margin-bottom:4rem;
}

.slideInDown{
    animation: slideInDown 750ms ;
}

.slideOutUp{
    animation: slideOutUp 750ms forwards;
    background-color: yellow;
}

@keyframes slideInDown{
    from{
        transform: translateY(-100%);
    }
    to{
        transform:translateY(0);
    }
}

@keyframes slideOutUp{
    from{
        transform:translateY(0);
    }
    to{
        transform: translateY(-100%);
    }
}