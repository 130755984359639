@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-hexagone-purple: #310046;
        --color-hexagone-yellow: #ffd200;
        --color-hexagone-black: #202020;
        --color-hexagone-text: #303030;
        --color-hexagone-grey: #999999;
        --color-hexagone-light-grey: #f6f6f6;
        --color-hexagone-darker-light-grey: #e6e6e6;
    }

    body {
        color: var(--color-hexagone-text);
        background-color: var(--color-hexagone-light-grey);
    }
}